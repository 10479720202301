















import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import { namespace } from 'vuex-class';
import { IProject } from '@store/modules/project/Interfaces';
import '../scss/InputControlActions.scss';

const NSProject = namespace('storeProject');
const NSInputControl = namespace('storeInputControl');

@Component({
    name: 'InputControlActions',
    components: {
        BaseSelect: () => import('@components/BaseSelect/BaseSelect.vue'),
        ButtonFavorite: () => import('@components/buttons/ButtonFavorite.vue'),
    }
})

export default class InputControlActions extends Vue {
    $refs!: {
        downloadLogs: HTMLFormElement;
    };

    @NSProject.Getter('projectData') projectData!: IProject;
    @NSInputControl.Action('A_GET_LOG_OF_INPUT_CONTROL') getLogOfInputControl!: (payload: {
        data: {
            appId: string | number;
            access_token: string | null;
        },
        fileName: string,
    }) => Promise<void>;

    downloadLogFile(): void {
        if (this.projectData && this.projectData.id && localStorage.getItem('token')) {
            this.getLogOfInputControl({
                data: {
                    appId: this.projectData.id,
                    access_token: localStorage.getItem('token'),
                },
                fileName: 'Журнал входного контроля.xlsx',
            });
        }
    }
}
